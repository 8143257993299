const Services = () => {
  return (
    <section
      id='services'
      className='section bg-gray'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h5
              className='sm-head wow fadeInUp'
              data-wow-delay='0.1s'
            >
              Services
            </h5>
            <h2
              className='wow fadeInUp'
              data-wow-delay='0.3s'
            >
              I offer these <span class='p-color'>services </span> for your Next
              project:
            </h2>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.5s'
            >
              <h4>
                <span className='p-color'>Full-Stack</span> Development
              </h4>
              <h1>01</h1>
              <p>
                End-to-end expertise in both frontend and backend technologies.
                Seamlessly integrate robust frontend interfaces with scalable
                and efficient backend solutions.
              </p>
            </div>
          </div>

          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.9s'
            >
              <h4>
                <span className='p-color'>Responsive</span> Web Design{" "}
              </h4>
              <h1>02</h1>
              <p>
                Craft websites that adapt seamlessly across various devices and
                screen sizes. Deliver optimal user experiences through fluid and
                responsive design principles.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.9s'
            >
              <h4>
                <span className='p-color'>Database </span>Management{" "}
              </h4>
              <h1>03</h1>
              <p>
                Proficient in SQL and NoSQL databases for structured and
                flexible data storage. Design and manage databases tailored to
                specific project requirements for efficient data handling.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.9s'
            >
              <h4>
                <span className='p-color'> API</span> Development{" "}
              </h4>
              <h1>04</h1>
              <p>
                Expertise in creating efficient and flexible APIs using GraphQL
                and Apollo. Utilize GraphQL to streamline data fetching,
                enhancing performance and customization capabilities.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.9s'
            >
              <h4>
                <span className='p-color'>CI/CD </span> Deployment{" "}
              </h4>
              <h1>05</h1>
              <p>
                Implement CI/CD pipelines for automated testing and seamless
                deployment. Streamline development workflows, ensuring code
                quality and rapid, reliable application releases.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.7s'
            >
              <h4>
                <span className='p-color'>Scalability</span> Performance{" "}
              </h4>
              <h1>06</h1>
              <p>
                Optimize applications for high performance and scalability.
                Employ strategies to handle increased loads, ensuring consistent
                and responsive user experiences.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.9s'
            >
              <h4>
                <span className='p-color'>Code</span> and Best Practices{" "}
              </h4>
              <h1>07</h1>
              <p>
                Adhere to coding standards, industry best practices, and
                maintain high code quality. Strive for clean, modular, and
                well-documented code to enhance readability, maintainability,
                and collaborative development.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.9s'
            >
              <h4>
                <span className='p-color'>Project </span>Management{" "}
              </h4>
              <h1>08</h1>
              <p>
                Proficient in project management tools, version control, and
                collaborative platforms. Foster effective teamwork by utilizing
                tools like Git, Jira, and collaborative workflows, ensuring
                efficient project delivery and seamless communication.
              </p>
            </div>
          </div>
          <div className='col-lg-4 col-md-6 col-sm-12'>
            <div
              className='s-card wow fadeInUp'
              data-wow-delay='0.9s'
            >
              <h4>
                <span className='p-color'>Innovative </span>Thinking{" "}
              </h4>
              <h1>09</h1>
              <p>
                Harnessing creative thinking and agile methodologies, I
                cultivate collaborative environments to deliver inventive
                solutions that transcend conventional approaches. Embracing
                diverse perspectives.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
