const Certificate = () => {
  return (
    <section className='section'>
      <div className='container'>
        <div className='row justify-content-center'>
          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/nodejs.jpg'
              alt='nodejs'
            />
          </div>

          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/nestjs.jpg'
              alt='nestjs'
            />
          </div>
          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/react.jpg'
              alt='reactjs'
            />
          </div>
          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/nextjs.jpg'
              alt='nextjs'
            />
          </div>
          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/angular.jpg'
              alt='angular'
            />
          </div>
          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/jsts.jpg'
              alt='js'
            />
          </div>
          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/mongo.jpg'
              alt='mongo'
            />
          </div>
          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/mysql.jpg'
              alt='mysql'
            />
          </div>
          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/postfreesql.jpg'
              alt='postgreSql'
            />
          </div>
          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/html.jpg'
              alt='html'
            />
          </div>
          <div
            className='col-lg-2 col-md-2 col-6 wow fadeInUp'
            data-wow-delay='0.1s'
          >
            {" "}
            <img
              className='st-img'
              src='/website/logo/express.jpg'
              alt='express'
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Certificate;
