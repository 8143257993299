const PortfolioCarusel = ({ curentProject }) => {
  const { sliderImages } = curentProject;
  return (
    <>
      <div
        id='carouselExampleControls'
        class='carousel slide'
        data-bs-ride='carousel'
      >
        <div class='carousel-inner'>
          {sliderImages?.map((x, i) => (
            <div
              key={i + 1}
              class={`carousel-item ${i === 0 ? "active" : ""}`}
            >
              <img
                src={x}
                class='d-block w-100'
                alt={curentProject.projectName}
              />
            </div>
          ))}
        </div>
        <button
          class='carousel-control-prev'
          type='button'
          data-bs-target='#carouselExampleControls'
          data-bs-slide='prev'
        >
          <span
            class='carousel-control-prev-icon'
            aria-hidden='true'
          ></span>
          <span class='visually-hidden'>Previous</span>
        </button>
        <button
          class='carousel-control-next'
          type='button'
          data-bs-target='#carouselExampleControls'
          data-bs-slide='next'
        >
          <span
            class='carousel-control-next-icon'
            aria-hidden='true'
          ></span>
          <span class='visually-hidden'>Next</span>
        </button>
      </div>
    </>
  );
};

export default PortfolioCarusel;
