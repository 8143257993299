import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faThumbsUp,
  faEye,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";

const AboutMe = () => {
  return (
    <section
      id='aboutMe'
      className='section'
    >
      {/* <!-- About Modal --> */}
      <div
        class='modal fade '
        id='aboutMoreModal'
        tabindex='-1'
        aria-labelledby='aboutMoreModalLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog modal-dialog-centered modal-xl'>
          <div class='modal-content bg-gray'>
            <div class='modal-header'>
              <h5
                class='modal-title'
                id='aboutMoreModalLabel'
              >
                More About Me
              </h5>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div class='modal-body'>
              <div className='row'>
                <div className='col-sm-12'>
                  <p>
                    I seamlessly integrate frontend and backend components,
                    delivering cohesive end-to-end solutions. With a keen eye
                    for detail, I emphasize best practices, version control, and
                    collaborative tools to ensure high-quality code.
                  </p>
                </div>
                <div className='row'>
                  <div className='col-sm-12 mt-3'>
                    <h4>
                      <FontAwesomeIcon
                        icon={faPaperPlane}
                        className='primary-color'
                      />{" "}
                      My Mission
                    </h4>
                  </div>
                  <div className='col-sm-12'>
                    <p className='pl-40'>
                      As a freelance software developer is to engineer
                      innovative solutions that exceed client expectations,
                      leveraging my expertise in diverse technologies. Fueled by
                      a commitment to code excellence and client satisfaction, I
                      aim to deliver impactful, scalable, and seamlessly
                      integrated software solutions.
                    </p>
                  </div>
                </div>
              </div>

              <div className='row'>
                <div className='col-sm-12 mt-1'>
                  <h4>
                    <FontAwesomeIcon
                      icon={faEye}
                      className='primary-color'
                    />{" "}
                    My Vision
                  </h4>
                </div>
                <div className='col-sm-12'>
                  <p className='pl-40'>
                    My main focus is to pioneer transformative digital
                    experiences, driving technological advancements through
                    creativity and precision. I aspire to be a catalyst for
                    positive change, crafting solutions that redefine industry
                    standards and empower businesses for sustained success.
                  </p>
                </div>
              </div>
              <div className='row'>
                <div className='col-sm-12 mt-2'>
                  <h4 className=''>
                    <FontAwesomeIcon
                      icon={faThumbsUp}
                      className='primary-color'
                    />{" "}
                    My Goal
                  </h4>
                </div>
                <div className='col-sm-12 pl-2'>
                  <p className='pl-40'>
                    Exceptional and innovative solutions, surpassing client
                    expectations, while continually refining my skills to stay
                    at the forefront of technological advancements.
                  </p>
                </div>
              </div>
            </div>
            <div class='modal-footer'>
              <button
                type='button'
                class='btn btn-primary'
                data-bs-dismiss='modal'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- About Modal --> */}

      <div className='container'>
        <div className='row'>
          <div
            class='col-lg-5 text-center mb-5 mb-lg-0 wow fadeInUp'
            data-wow-delay='0.2s'
          >
            {" "}
            <img
              class='img-fluid shadow-md rounded d-inline-block wow fadeInUp'
              src='./about-me.webp'
              data-wow-delay='0.1s'
              title='Muhammad Ahsan Hameed'
              alt=''
            />{" "}
          </div>
          <div
            class='col-lg-7 text-center text-lg-start ps-lg-4 wow fadeInUp'
            data-wow-delay='0.2s'
            // style="visibility: visible; animation-delay: 0.2s;"
          >
            <h5
              className='sm-head wow fadeInUp'
              data-wow-delay='0.1s'
            >
              About Me
            </h5>
            <h2
              class='mb-4 wow fadeInUp'
              data-wow-delay='0.1s'
            >
              Hello! <span class='p-color fw-600'>I'm Ahsan</span>
            </h2>
            <p
              className='justify-p wow fadeInUp'
              data-wow-delay='0.7s'
            >
              As a seasoned freelance software developer, I bring a wealth of
              expertise in a diverse set of technologies, specializing in
              <strong class='fw-600'> backend frameworks</strong> such as
              Node.js, Express.js, and Nest.js. My proficiency extends to
              TypeScript and JavaScript, ensuring code quality and
              maintainability. I am well-versed in GraphQL, utilizing Apollo for
              efficient API development and integrating Swagger Docs for
              <strong class='fw-600'> comprehensive API documentation</strong>.
              On the frontend, I excel in{" "}
              <strong class='fw-600'> Angular</strong> with RxJS for building
              reactive and scalable applications, while also leveraging the
              versatility of{" "}
              <strong class='fw-600'> React.js and Next.js</strong>. Real-time
              communication is a forte, demonstrated through my adept use of
              Socket.io for implementing dynamic, real-time features.
            </p>
            <p
              className='justify-p wow fadeInUp'
              data-wow-delay='0.7s'
            >
              Clients benefit not only from my technical acumen but also from
              effective communication, project management skills, and a{" "}
              <strong class='fw-600'>proven track record</strong> of delivering
              successful outcomes in the world of freelance software
              development.
            </p>
            <div class='brands-grid separator-border my-sm-4'>
              <div class='row'>
                <div class='col-sm-4 py-4 py-sm-2'>
                  <div
                    class='featured-box text-center wow fadeInUp'
                    data-wow-delay='0.7s'
                  >
                    <h4 class='text-8 text-light-emphasis mb-0'>
                      <span
                        class='counter'
                        data-from='0'
                        data-to='10'
                      >
                        7
                      </span>
                      +
                    </h4>
                    <p class='mb-0'>Years Experiance</p>
                  </div>
                </div>
                <div class='col-sm-4 py-4 py-sm-2'>
                  <div
                    class='featured-box text-center wow fadeInUp'
                    data-wow-delay='0.7s'
                  >
                    <h4 class='text-8 text-light-emphasis mb-0'>
                      <span
                        class='counter'
                        data-from='0'
                        data-to='350'
                      >
                        200
                      </span>
                      +
                    </h4>
                    <p class='mb-0'>Happy Clients</p>
                  </div>
                </div>
                <div class='col-sm-4 py-4 py-sm-2'>
                  <div
                    class='featured-box text-center wow fadeInUp'
                    data-wow-delay='0.7s'
                  >
                    <h4 class='text-8 text-light-emphasis mb-0'>
                      <span
                        class='counter'
                        data-from='0'
                        data-to='780'
                      >
                        750
                      </span>
                      +
                    </h4>
                    <p class='mb-0'>Projects Done</p>
                  </div>
                </div>
              </div>
            </div>
            {/* <a
              class="btn btn-secondary rounded-pill mt-3"
              data-bs-toggle="modal"
              data-bs-target="#about-us-details"
              href="#"
            >
              About more
            </a>{" "} */}
            <div className='row'>
              <div
                className='col-md-3 wow fadeInUp'
                data-wow-delay='0.7s'
              >
                <button
                  type='submit'
                  className='btn btn-primary'
                  data-bs-toggle='modal'
                  data-bs-target='#aboutMoreModal'
                >
                  About more
                </button>{" "}
              </div>

              <div className='col-md-5 wow fadeInUp pt-10px'>
                <a
                  href='#portfolio'
                  class=' btn-link smooth-scroll px-4'
                >
                  Discover My Work
                  {/* <span class="text-1 ms-2">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="1em"
                      viewBox="0 0 320 512"
                    >
                      <path d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z" />
                    </svg>
                  </span> */}
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMe;
