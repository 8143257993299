import { useState } from "react";
import PortfolioCarusel from "./portfolio-carusel";

const Portfolio = () => {
  const [activeLink, setActiveLink] = useState("All");
  const [curentProject, setCurentProject] = useState(0);
  const links = [
    "All",
    "MERN", // Web Development
    "MEAN", // Backend Development
  ];

  const portfolioImages = [
    // Remynt
    {
      src: "./website/remynt/home.webp",
      projectName: "Get Remynt",
      // title and tab name shuld be same to show in tab wise.
      title: "MERN",
      info: "Remynt empowers consumers to rebuild their credit by resolving delinquent debt.  We acquire charged-off credit card debt and offer those consumers the opportunity to earn credit, a savings account, financial management tools, a community, and rewards for debt repayment.",
      client: "Founder Get Remynt",
      industry: "Bank",
      features: "Multiple Payment Integrations, User informations etc. ",
      technologies: "ReactJs, NodeJS, Express, Mongoose and MongoDB",
      date: "2023",
      url: "https://www.getremynt.com",
      sliderImages: [
        "./website/remynt/home.webp",
        "./website/remynt/about.webp",
      ],
      isAll: true,
    },
    // Golden Hit
    {
      src: "./website/goldenHitt/all.webp",
      projectName: "Golden Hit",
      // title and tab name shuld be same to show in tab wise.
      title: "MERN",
      info: "Immerse yourself in the thrilling world of risk and reward with our exhilarating Crash Game web experience, where players predict the perfect moment to cash out for maximum winnings!.",
      client: "Singapore Company",
      industry: "Online Casino",
      features:
        "Chat, Customer Support, Admin Panel, Dark and light mode, etc.",
      technologies: "ReactJs, NodeJS, Express, Sockets, Sequlize and MySql",
      date: "2023",
      url: "https://golden-hit.com",
      sliderImages: [
        "./website/goldenHitt/all.webp",
        // "./website/golden-hitt/about.webp",
      ],
      isAll: false,
    },
    // Fractional
    {
      src: "./website/fractional/home.webp",
      projectName: "Fractional",
      // title and tab name shuld be same to show in tab wise.
      title: "MERN",
      info: "Blockchain empowers all, irrespective of financial status, class, or location, and Fractional utilizes this technology to make the dream of owning rare and valuable minerals and gemstones a reality.",
      client: "Founder Rendr",
      industry: "Blockchain",
      features: "Single page app, PWA, EmailJs integration",
      technologies: "ReactJs",
      date: "2023",
      url: "https://fractional.rocks",
      sliderImages: [
        "./website/fractional/home.webp",
        "./website/fractional/team.webp",
      ],
      isAll: false,
    },
    // click Work
    {
      src: "./website/clickWork/main.webp",
      projectName: "Clickwrk",
      // title and tab name shuld be same to show in tab wise.
      title: "MERN",
      info: "Discover skilled tradespeople at Clickwrk. Easily find the perfect match for your project needs. From plumbers to electricians and more, our network of trusted professionals is ready to assist you.",
      client: "Founder Ezra",
      industry: "SaaS",
      features:
        "Chat, Admin Panel, Hire and complete order process, light and dark mode",
      technologies: "NextJs, Node.js, Socket.io, Mongoose, and MongoDB,",
      date: "2023",
      url: "https://clickwrk.com",
      sliderImages: [
        "./website/clickWork/main.webp",
        "./website/clickWork/listing.webp",
        "./website/clickWork/footer.webp",
      ],
      isAll: true,
    },
    // Meld
    {
      src: "./website/meld/meld.webp",
      projectName: "Meld",
      // title and tab name shuld be same to show in tab wise.
      title: "MERN",
      info: "MELD is a web3 native, tokenized NFT, art, blockchain, and entertainment innovation experience providing educational content, art experiences, networking, parties, performances, and immaculate vibes all in one location.",
      client: "Founder Rendr",
      industry: "Organize Events",
      features: "Single page app",
      technologies: "ReactJs",
      date: "2023",
      url: "https://meld.wtf",
      sliderImages: [
        "./website/meld/meld.webp",
        "./website/meld/meld-laptop.webp",
      ],
      isAll: true,
    },

    // Vapesuites
    {
      src: "./website/vape/grid.webp",
      projectName: "Vapesuite",
      // title and tab name shuld be same to show in tab wise.
      title: "MEAN",
      info: "Explore a diverse selection of premium vaping products at VapeSuite, your one-stop eCommerce destination for top-tier e-liquids, cutting-edge devices, and accessories. Elevate your vaping experience with our curated collection, designed for enthusiasts seeking quality and style in every puff.",
      client: "Allomate Solutions",
      industry: "E-commerce",
      features: "Fully custom and dynamic, paypal, cart, admin panel",
      technologies: "Agnular SSR, NestJs, Postgresql, SocketIo, Rxjs etc.",
      date: "2020",
      url: "https://vapesuite.co.uk",
      sliderImages: [
        "./website/vape/home.webp",
        "./website/vape/grid.webp",
        "./website/vape/listing.webp",
        "./website/vape/footer.webp",
        "./website/vape/checkout.webp",
      ],
      isAll: true,
    },
    // PSL
    {
      src: "./website/psl/home.webp",
      projectName: "Pakistan Sign Language",
      // title and tab name shuld be same to show in tab wise.
      title: "MEAN",
      info: "The native language of Pakistan’s Deaf community is Pakistan Sign Language (PSL). Similar to spoken languages, PSL has a variety of dialects in different regions of the country. While many common words are shared, some will be regional-specific. Deaf educa",
      client: "Allomate Solutions",
      industry: "E-Learning",
      features: "Fully custom and dynamic and Video Streaming",
      technologies: "Agnular SSR, NestJs, Postgresql, SocketIo, Rxjs etc.",
      date: "2020",
      url: "https://psl.org.pk",
      sliderImages: [
        "./website/psl/home.webp",
        "./website/psl/list.webp",
        "./website/psl/play.webp",
        "./website/psl/videos.webp",
      ],
      isAll: false,
    },

    // Sell360
    {
      src: "./website/sell360/home.webp",
      projectName: "Sell360",
      // title and tab name shuld be same to show in tab wise.
      title: "MEAN",
      info: "SELL 360 is a SaaS designed to automate and digitize field sales operations for FMCG & CPG Industry.",
      client: "Allomate Solutions",
      industry: "SaaS for Sales operations for FMCG & CPG Industry",
      features:
        "Real time business inteligence, Automate sales processes, On-field productivity, Real time sales data etc.",
      technologies: "Agnular, NodeJs, Mysql, Socket.io, Rxjs etc.",
      date: "2020",
      url: "https://sell360.app",
      sliderImages: [
        "./website/sell360/home.webp",
        "./website/sell360/one.webp",
      ],
      isAll: false,
    },

    // RevBits
    {
      src: "./website/revbits/all.webp",
      projectName: "RevBits",
      // title and tab name shuld be same to show in tab wise.
      title: "MEAN",
      info: "RevBits was founded in 2016 by CTO Mucteba Celik, as a cybersecurity services provider for penetration testing, malware analysis, code review, and incident response. Initially, RevBits focused on ",
      client: "Invozone Solutions Services",
      industry: "Cybersecurity Solutions",
      features:
        "Penetration Testing, Incident Response Services, Incident Response Services, Code Review, etc.",
      technologies: "Agnular, NodeJs, Postgresql, Socket.io, Rxjs etc.",
      date: "2021",
      url: "https://www.revbits.com",
      sliderImages: [
        "./website/revbits/all.webp",
        "./website/revbits/s1.jpg",
        "./website/revbits/s2.webp",
        "./website/revbits/s3.webp",
      ],
      isAll: true,
    },
    // Boostopia
    {
      src: "./website/boostopia/all.webp",
      projectName: "Boostopia",
      // title and tab name shuld be same to show in tab wise.
      title: "MERN",
      info: "Online gaming plateform for gamers who want to update there ranks but don't have a time to hire some other gamers from there to update there ranks and keep updated the game.",
      client: "CEO Boostopia",
      industry: "SaaS",
      features:
        "Live chat, Process Order, Client and Customer dashboards, Payment intgrations, etc",
      technologies:
        "NextJs, NestJs, Type ORM, Postgresql, Socket.io, Swagger, etc.",
      date: "2022",
      url: "https://boostopia.gg/",
      sliderImages: [
        "./website/boostopia/all.webp",
        "./website/boostopia/all.webp",
      ],
      isAll: true,
    },
  ];

  const onGallaryNavClick = (value) => {
    setActiveLink(value);
  };
  const onProjectDetailClick = (index) => {
    debugger;
    setCurentProject({ ...portfolioImages[index] });
  };
  return (
    <section
      id='portfolio'
      className='section'
    >
      {/* <!-- START Portfolio Modal --> */}
      <div
        class='modal fade '
        id='portfolioModal'
        tabindex='-1'
        aria-labelledby='portfolioModalLabel'
        aria-hidden='true'
      >
        <div class='modal-dialog modal-dialog-centered modal-xl'>
          <div class='modal-content bg-gray'>
            <div class='modal-header'>
              <h5
                class='modal-title'
                id='portfolioModalLabel'
              >
                {curentProject.projectName}
              </h5>
              <button
                type='button'
                class='btn-close'
                data-bs-dismiss='modal'
                aria-label='Close'
              ></button>
            </div>
            <div class='modal-body'>
              <div className='row'>
                <div className='col-sm-6'>
                  {curentProject && (
                    <PortfolioCarusel curentProject={curentProject} />
                  )}
                </div>

                <div className='col-sm-6'>
                  <h3> Project Info:</h3>
                  <p>{curentProject.info}</p>

                  <h3> Project Details:</h3>
                  <div className='pt-3'>
                    <span className='small-heading'>Client: </span>

                    {curentProject.client}
                    <hr />
                  </div>
                  <div className='pt-3'>
                    <span className='small-heading'>Industry: </span>
                    {curentProject.industry}
                    <hr />
                  </div>

                  <div className='pt-3'>
                    <span className='small-heading'>Features: </span>
                    {curentProject.features}
                    <hr />
                  </div>
                  <div className='pt-3'>
                    <span className='small-heading'>Technologies: </span>
                    {curentProject.technologies}
                    <hr />
                  </div>
                  <div className='pt-3'>
                    <span className='small-heading'>Date: </span>
                    {curentProject.date}
                    <hr />
                  </div>
                  <div className='pt-3'>
                    <span className='small-heading'>URL: </span>
                    <a
                      target='_blank'
                      href={curentProject.url}
                      rel='noreferrer'
                    >
                      {curentProject.url}
                    </a>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
            <div class='modal-footer'>
              <button
                type='button'
                class='btn btn-primary'
                data-bs-dismiss='modal'
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- END Portfolio Modal --> */}
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h5
              className='sm-head wow fadeInUp'
              data-wow-delay='0.1s'
            >
              Portfolio
            </h5>
            <h2
              className='wow fadeInUp'
              data-wow-delay='0.2s'
            >
              I help you build <span className='p-color'>brand</span> for your
              business:
              {/* at an affordable price. Thousands of clients have procured exceptional results while working with Me. */}
            </h2>
          </div>
        </div>

        <ul className='nav nav-pills text-uppercase justify-content-center border-bottom-0 mb-5 fadeInUp'>
          {links.map((x) => (
            <li className='nav-item'>
              {" "}
              <a
                class={`nav-link ${activeLink == x ? "active-port-link" : ""}`}
                onClick={() => onGallaryNavClick(x)}
              >
                {x}
              </a>
            </li>
          ))}
        </ul>

        <div className='row'>
          <div className='col-lg-12'>
            <div
              className='row wow '
              data-wow-delay='0.3s'
            >
              {portfolioImages.map((x, i) => (
                <>
                  {" "}
                  {activeLink === x.title ||
                  (activeLink === "All" && x?.isAll) ? (
                    <div
                      key={i + 1}
                      className='col-lg-4 p-0 p-2 zoomIn image-portfolio-div'
                      data-wow-delay={`${0.1 + i / 10}s`}
                      onClick={() => {
                        setCurentProject(null);
                        return onProjectDetailClick(i);
                      }}
                    >
                      <img
                        className='img-fluid shadow-md rounded d-inline-block'
                        src={x.src}
                        title={x.title}
                        alt={x.title}
                      />
                      <div class='portfolio-overlay m-2'>
                        {" "}
                        <a
                          class='popup-ajax stretched-link'
                          data-bs-toggle='modal'
                          data-bs-target='#portfolioModal'
                        ></a>
                        <div class='portfolio-overlay-details'>
                          <p class='text-white text-6'>
                            <i class='fas fa-plus'></i>
                          </p>
                          <h5 class='text-white fw-400'>{x?.projectName}</h5>
                          <span class='text-light'>More Detail</span>{" "}
                        </div>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
