import React from "react";
// import OwlCarousel from "react-owl-carousel";
// import "owl.carousel/dist/assets/owl.carousel.css";
// import "owl.carousel/dist/assets/owl.theme.default.css";

const Testimonial = () => {
  return (
    <>
      <section
        id='testimonial'
        className='section bg-gray'
      >
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h5
                className='sm-head wow fadeInUp'
                data-wow-delay='0.1s'
              >
                Our Clients
              </h5>
              <h2
                className='wow fadeInUp'
                data-wow-delay='0.3s'
              >
                See what my <span className='p-color'>happy clients</span> say
                about me:
              </h2>
            </div>

            <div class='row align-items-md-center'>
              <div class='col-lg-12 col-md-12 col-sm-12'>
                <div
                  id='carouselExampleCaptions'
                  class='carousel slide'
                  data-bs-ride='carousel'
                >
                  {/* <!-- Wrapper for slides --> */}
                  <div class='carousel-inner'>
                    <div class='carousel-item active'>
                      <div class='row p-4'>
                        <div class='t-card'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            fill='#62c1e5'
                            viewBox='0 0 448 512'
                          >
                            <path d='M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z' />
                          </svg>
                          <p class='mt-3'>
                            Exceptional software developer with a knack for
                            problem-solving. Demonstrates unparalleled expertise
                            in crafting innovative solutions that elevate
                            project outcomes.
                          </p>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            fill='#62c1e5'
                            viewBox='0 0 448 512'
                          >
                            <path d='M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z' />
                          </svg>
                        </div>
                        <div class='row'>
                          <div class='col-sm-2 pt-3'>
                            <img
                              src='../testimonial/wakasbajwa.jpeg'
                              class='rounded-circle img-responsive img-fluid img-testimonial'
                              alt='saf'
                            />
                          </div>
                          <div class='col-sm-10'>
                            <div class='arrow-down d-none d-lg-block'></div>
                            <h4 className='mt-3'>
                              <strong>
                                <span class='p-color'>Wakas Bajwa</span>
                              </strong>
                            </h4>
                            <p class='testimonial_subtitle'>
                              <span>
                                CEO and Co-founder at Allomate Solutions (PAK)
                              </span>
                              {/* <span>CodeHim</span> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='carousel-item'>
                      <div class='row p-4'>
                        <div class='t-card'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            fill='#62c1e5'
                            viewBox='0 0 448 512'
                          >
                            <path d='M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z' />
                          </svg>
                          <p class='mt-3'>
                            {" "}
                            An exceptional Backend Engineer, he not only solves
                            problems and optimizes performance but also brings
                            insightful ideas to enhance our product. His
                            responsiveness and ability to appreciate and
                            implement changes during the project have been
                            instrumental in achieving outstanding results.
                          </p>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            viewBox='0 0 448 512'
                            fill='#62c1e5'
                          >
                            <path d='M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z' />
                          </svg>
                        </div>
                        <div class='row'>
                          <div class='col-sm-2 pt-4'>
                            <img
                              src='../testimonial/rendr.jpg'
                              class='rounded-circle img-responsive img-fluid img-testimonial'
                              alt='ssf'
                            />
                          </div>
                          <div class='col-sm-10'>
                            <div class='arrow-down d-none d-lg-block'></div>
                            <h4 className='mt-3'>
                              <strong>
                                <span class='p-color'>Rendr</span>
                              </strong>
                            </h4>
                            <p class='testimonial_subtitle'>
                              <span>
                                Founder Blockchain Developer, Mineralogist and
                                Decentralization Advocate (US)
                              </span>

                              {/* <span>Vaxa digital</span> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class='carousel-item'>
                      <div class='row p-4'>
                        <div class='t-card'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            viewBox='0 0 448 512'
                            fill='#62c1e5'
                          >
                            <path d='M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z' />
                          </svg>
                          <p class='mt-3'>
                            Honestly I was scared to work with Ahsan, because he
                            had only a few reviews and I clearly don't have
                            money to waste. But it turned out great, my stripe
                            implementation wasn't easy to work on because I
                            didn't have a proper Docker setup but still he asked
                            questions to get the data he needed and managed to
                            do what I asked. I would definitely work again with
                            him, I recommand.
                          </p>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            fill='#62c1e5'
                            viewBox='0 0 448 512'
                          >
                            <path d='M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z' />
                          </svg>
                        </div>
                        <div class='row text-lg-start'>
                          <div class='col-sm-2 pt-4 align-items-center'>
                            <img
                              src='../testimonial/hugo.jpeg'
                              class='rounded-circle img-responsive img-fluid img-testimonial'
                              alt='cilent'
                            />
                          </div>
                          <div class='col-sm-10'>
                            <div class='arrow-down d-none d-md-block'></div>
                            <h4 className='mt-3'>
                              <strong>
                                <span class='p-color'>Hugo Roch </span>
                              </strong>
                            </h4>
                            <p class='testimonial_subtitle'>
                              <span>Software Developer (France)</span>

                              {/* <span>Ca</span> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class='carousel-item'>
                      <div class='row p-4'>
                        <div class='t-card'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            viewBox='0 0 448 512'
                            fill='#62c1e5'
                          >
                            <path d='M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z' />
                          </svg>
                          <p class='mt-3'>
                            Really professional developer, pixel perfect,
                            amazing work. Build an outstanding site, very high
                            quality. Amazing work, as always, flawless design,
                            fluent development, all built with precision. Highly
                            professional, native communication.
                          </p>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            fill='#62c1e5'
                            viewBox='0 0 448 512'
                          >
                            <path d='M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z' />
                          </svg>
                        </div>
                        <div class='row text-lg-start'>
                          <div class='col-sm-2 pt-4 align-items-center'>
                            <img
                              src='../testimonial/stas.jpeg'
                              class='rounded-circle img-responsive img-fluid img-testimonial'
                              alt='cilent'
                            />
                          </div>
                          <div class='col-sm-10'>
                            <div class='arrow-down d-none d-md-block'></div>
                            <h4 className='mt-3'>
                              <strong>
                                <span class='p-color'>Stas Sorokin </span>
                              </strong>
                            </h4>
                            <p class='testimonial_subtitle'>
                              <span>
                                Chief Technology Officer at CherryPop (UK)
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class='carousel-item'>
                      <div class='row p-4'>
                        <div class='t-card'>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            viewBox='0 0 448 512'
                            fill='#62c1e5'
                          >
                            <path d='M0 216C0 149.7 53.7 96 120 96h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V320 288 216zm256 0c0-66.3 53.7-120 120-120h8c17.7 0 32 14.3 32 32s-14.3 32-32 32h-8c-30.9 0-56 25.1-56 56v8h64c35.3 0 64 28.7 64 64v64c0 35.3-28.7 64-64 64H320c-35.3 0-64-28.7-64-64V320 288 216z' />
                          </svg>
                          <p class='mt-3'>
                            Thank you for your work. Ahsan has excellent
                            experience and understanding person. If I have some
                            more work in the future I will Hire Ahsan. I
                            recomanded Ahsan.
                          </p>
                          <svg
                            xmlns='http://www.w3.org/2000/svg'
                            height='2em'
                            fill='#62c1e5'
                            viewBox='0 0 448 512'
                          >
                            <path d='M448 296c0 66.3-53.7 120-120 120h-8c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H320c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72zm-256 0c0 66.3-53.7 120-120 120H64c-17.7 0-32-14.3-32-32s14.3-32 32-32h8c30.9 0 56-25.1 56-56v-8H64c-35.3 0-64-28.7-64-64V160c0-35.3 28.7-64 64-64h64c35.3 0 64 28.7 64 64v32 32 72z' />
                          </svg>
                        </div>
                        <div class='row text-lg-start'>
                          <div class='col-sm-2 pt-4 align-items-center'>
                            <img
                              src='../testimonial/lumanton.jpeg'
                              class='rounded-circle img-responsive img-fluid img-testimonial'
                              alt='cilent'
                            />
                          </div>
                          <div class='col-sm-10'>
                            <div class='arrow-down d-none d-md-block'></div>
                            <h4 className='mt-3'>
                              <strong>
                                <span class='p-color'>Lumaanton</span>
                              </strong>
                            </h4>
                            <p class='testimonial_subtitle'>
                              <span>Cofound Client Bookup (US)</span>

                              {/* <span>Ca</span> */}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class='controls push-right'>
                  <a
                    class='btn btn-primary smooth-scroll mt-2 rubberBand m-1'
                    href='#carouselExampleCaptions'
                    data-bs-slide='prev'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      height='2em'
                      viewBox='0 0 320 512'
                      fill='#ffffff'
                    >
                      {" "}
                      <path d='M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l192 192c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L77.3 256 246.6 86.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-192 192z' />
                    </svg>
                  </a>
                  <a
                    class='btn btn-primary smooth-scroll mt-2 rubberBand m-1'
                    href='#carouselExampleCaptions'
                    data-bs-slide='next'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      height='2em'
                      fill='#ffffff'
                      viewBox='0 0 320 512'
                    >
                      <path d='M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z' />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Testimonial;
