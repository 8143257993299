import Skills from "./skills";

const Experience = () => {
  return (
    <section
      id='experience-reference'
      className='section'
    >
      <div className='container'>
        <div className='row'>
          <div className='col-12'>
            <h5
              className='sm-head wow fadeInUp'
              data-wow-delay='0.1s'
            >
              Experience & Reference
            </h5>
            <h2
              className='wow fadeInUp'
              data-wow-delay='0.2s'
            >
              It is a long <span className='p-color'>fact that a reader</span>{" "}
              will be distracted:
            </h2>
          </div>

          <div className='col-lg-12 col-md-12'>
            <div className='resume-box'>
              <ul>
                <li>
                  <div
                    className='icon wow fadeInUp'
                    data-wow-delay='0.1s'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='black'
                      className='bi bi-briefcase'
                      viewBox='0 0 16 16'
                    >
                      <path d='M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z' />
                    </svg>
                  </div>
                  <span
                    className='time open-sans-font text-uppercase wow fadeInUp'
                    data-wow-delay='0.2s'
                  >
                    Oct 2022 - till today
                  </span>
                  <h5
                    className='poppins-font text-uppercase wow fadeInUp'
                    data-wow-delay='0.3s'
                  >
                    Full-time Freelance Software Engineer
                  </h5>
                  <p
                    className='open-sans-font wow fadeInUp'
                    data-wow-delay='1s'
                  >
                    I began my freelancing journey on Fiverr, completing
                    numerous successful projects and earning 5-star client
                    satisfaction.
                  </p>
                  <ul
                    class='substyle wow fadeInUp'
                    data-wow-delay='1.2s'
                  >
                    <li>
                      Hired and managed developers, working as a self-employed
                      project manager, overseeing both backend and frontend
                      integrations to ensure project completion and meet
                      deadlines.
                    </li>
                    <li>
                      Proficient in React.js, Next.js, and Angular for frontend
                      development, with expertise in HTML and CSS for static
                      sites, portfolios, and business projects, including
                      accounting applications for companies.
                    </li>
                    <li>
                      Led the Remynt project, focusing on debt repayment, while
                      working full-time as a versatile full-stack developer.
                    </li>
                    <li>
                      Spearheaded a full-time e-commerce project, handling
                      backend development, API integrations, and hiring frontend
                      developers to achieve a 100% pixel-perfect UI.
                    </li>
                    <li>
                      Engaged in a personal project involving Nest.js and
                      MongoDB for wholesale and retail management, creating
                      reports in PDF, showcasing analytics, and more.
                    </li>
                    <li>
                      Currently working remotely as a full-stack developer,
                      leading teams and staying abreast of the latest
                      technologies, reading and implementing them with passion.
                    </li>

                    <li>
                      Proficient in creating detailed reports in PDF format,
                      showcasing analytical insights, and implementing a wide
                      range of functionalities.
                    </li>

                    <li>
                      Demonstrated leadership skills by leading teams and
                      currently thriving as a remote full-stack developer,
                      ensuring seamless collaboration and project success.
                    </li>

                    <li>
                      Passionate about staying updated with the latest
                      technologies, consistently integrating new advancements
                      into projects for enhanced efficiency and innovation.
                    </li>
                  </ul>
                  <div
                    className='icon wow fadeInUp'
                    data-wow-delay='0.1s'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='black'
                      className='bi bi-briefcase'
                      viewBox='0 0 16 16'
                    >
                      <path d='M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z' />
                    </svg>
                  </div>
                  <span
                    className='time open-sans-font text-uppercase wow fadeInUp'
                    data-wow-delay='0.2s'
                  >
                    Apr 2020 - Oct 2022 (2 year and 5 months)
                  </span>
                  <h5
                    className='poppins-font text-uppercase wow fadeInUp'
                    data-wow-delay='0.3s'
                  >
                    Full-time Full Stack Developer at Allomate Solutions
                  </h5>
                  <p
                    className='open-sans-font wow fadeInUp'
                    data-wow-delay='1s'
                  >
                    I lead the development of information systems, conducting
                    operational studies and guiding the software team in
                    designing and installing solutions. My role includes
                    fostering an innovative environment, utilizing strong
                    leadership to ensure successful project outcomes.
                  </p>
                  <ul
                    class='substyle wow fadeInUp'
                    data-wow-delay='1.2s'
                  >
                    <li>
                      Led the development of a fully customized and dynamic
                      e-commerce platform featuring 15 distinct discount deals.
                      Responsible for both backend and frontend development
                      using Nest.js and Angular.
                    </li>
                    <li>
                      Contributed as a Senior Developer at Revbits, a
                      cybersecurity management software company. Collaborated
                      within a 30-member team, working with technologies such as
                      Angular, Node.js, and Express.
                    </li>
                    <li>
                      Engaged in the development of a Salesforce-like software
                      solution, managing the sales flow from company to
                      distributors and mobile apps. Took charge of backend
                      development using Node.js and crafted an admin site
                      dashboard in Angular.
                    </li>
                    <li>
                      Integrated a real-time chat service into a SaaS
                      application, resembling the functionality of platforms
                      like Fiverr.
                    </li>
                    <li>
                      Currently involved in various Node.js and frontend
                      projects, including Sales Khata, HTML to React
                      conversions, and API development using Apollo GraphQL,
                      Swagger Docs, and React.js.
                    </li>
                    <li>
                      Played a pivotal role as the main Senior Backend Node.Js
                      Engineer in the Delivigo app, functioning similarly to
                      food delivery platforms like Food Panda.
                    </li>
                  </ul>
                  <div
                    className='icon wow fadeInUp'
                    data-wow-delay='0.7s'
                  >
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='16'
                      height='16'
                      fill='black'
                      className='bi bi-briefcase'
                      viewBox='0 0 16 16'
                    >
                      <path d='M6.5 1A1.5 1.5 0 0 0 5 2.5V3H1.5A1.5 1.5 0 0 0 0 4.5v8A1.5 1.5 0 0 0 1.5 14h13a1.5 1.5 0 0 0 1.5-1.5v-8A1.5 1.5 0 0 0 14.5 3H11v-.5A1.5 1.5 0 0 0 9.5 1h-3zm0 1h3a.5.5 0 0 1 .5.5V3H6v-.5a.5.5 0 0 1 .5-.5zm1.886 6.914L15 7.151V12.5a.5.5 0 0 1-.5.5h-13a.5.5 0 0 1-.5-.5V7.15l6.614 1.764a1.5 1.5 0 0 0 .772 0zM1.5 4h13a.5.5 0 0 1 .5.5v1.616L8.129 7.948a.5.5 0 0 1-.258 0L1 6.116V4.5a.5.5 0 0 1 .5-.5z' />
                    </svg>
                  </div>
                  <span
                    className='time open-sans-font text-uppercase wow fadeInUp'
                    data-wow-delay='0.8s'
                  >
                    Feb 2019 - Apr 2020 (1 year)
                  </span>
                  <h5
                    className='poppins-font text-uppercase wow fadeInUp'
                    data-wow-delay='0.9s'
                  >
                    Full-time Mean/Mern Stack Developer
                    <span></span>
                  </h5>
                  <p
                    className='open-sans-font wow fadeInUp'
                    data-wow-delay='1s'
                  >
                    My responsibility involves crafting information systems
                    through in-depth operational study, designing, and
                    installing software solutions. Additionally, I provide
                    support and leadership to the software team, ensuring the
                    development of new websites and the maintenance of existing
                    ones.
                  </p>
                  <ul
                    class='substyle wow fadeInUp'
                    data-wow-delay='1.2s'
                  >
                    <li>
                      Develop software solutions through comprehensive analysis,
                      system study, and collaboration with users, following the
                      software development lifecycle.
                    </li>
                    <li>
                      Evaluate operational feasibility by assessing
                      requirements, proposing solutions, and documenting clear,
                      well-demonstrated solutions using various visual aids.
                    </li>
                    <li>
                      Prepare and install solutions with precision, considering
                      system specifications, standards, and effective
                      programming techniques.
                    </li>
                    <li>
                      Enhance operational efficiency by conducting thorough
                      systems analysis, recommending policy changes, and staying
                      updated on the latest development tools and techniques.
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <Skills />
        </div>
      </div>
    </section>
  );
};

export default Experience;
